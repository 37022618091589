import React from "react";
import Navbar from "./Navbar";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#325875",
        height:'100vh'
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    footer: {
        fontSize: 10,
        marginBottom: "10px",
        color: '#d9d9d9',
        letterSpacing: 3
    },
    banner: {
        fontSize: 18,
        marginTop:'20%',
        marginBottom: "10px",
        color: '#d9d9d9',
        letterSpacing: 2
    },
}));

const Home = () => {
  const classes = useStyles();
  return(
    <div className={classes.root}>
    <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
            <Navbar />
        </Grid>
        <Grid item xs={12} align="center">
            <Typography className={classes.banner} gutterBottom>
                Only authorized users can use TF<sup>+</sup>. Please login to continue or contact us.
            </Typography>
        </Grid>
        <Grid item xs={12} >
            {/* <ExcelReader data={data} /> */}
        </Grid>
        <Grid align="center" item xs={12}>
            <Typography className={classes.footer} gutterBottom>
                COPYRIGHT &#169; 2021 MONTE CARLO PLUS &#183; info[at]montecarloplus.com
            </Typography>
        </Grid>
    </Grid>
</div>
  );
};

export default Home;