import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Login from './Login'
import Logout from "./Logout";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    navbar: {
        backgroundColor: "#1e375e",
    },
    title:{
        fontSize: 24,
        letterSpacing: 3,
        color: '#47c5ff',
        // fontWeight: 'bold'
    },
    userInfo: {
        display :'flex',
        marginLeft: 'auto', 
        marginRight: 0,
        verticalAlign:'middle',
        alignItems:'center'
    }
}));


export default function Navbar() {
    const classes = useStyles();
    const { user, isAuthenticated, isLoading } = useAuth0();

    let url = process.env.PUBLIC_URL + '/tr_logo.png';
    return (
        // <div className={classes.root}>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className={classes.navbar} position="static">
                <Toolbar>
                    <Avatar sx={{ mr: 2 }} variant={"square"} alt="Trade Finance Plus" src={url} style={{
                        width: 85,
                        height: 70
                    }} />
                     <Typography className={classes.title} sx={{ flexGrow: 2 }}>
                        TRADE FINANCE PLUS
                    </Typography>
                    <div className={classes.userInfo}>
                        {!isAuthenticated && <Login />}
                        {isAuthenticated && (
                        <Button color='inherit' sx={{ flexGrow: 1 }}>
                            {user.name}
                        </Button>)}
                        {isAuthenticated && <Logout />}
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
        // </div>
    );
}
