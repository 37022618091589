export const UPDATE_CELL_VALUE = "UPDATE_CELL_VALUE";
export const UPDATE_DATA = "UPDATE_DATA";
export const INSERT_ROW = "INSERT_ROW";
export const DELETE_ROW = "DELETE_ROW";
export const UPDATE_GENERIC_INPUTS = "UPDATE_GENERIC_INPUTS";


export const updateCellValue = (payload) => {
    return { type: UPDATE_CELL_VALUE, payload };
};

export const updateData = (payload) => {
    return { type: UPDATE_DATA, payload };
};

export const insertRow = (payload) => {
    return { type: INSERT_ROW, payload };
};

export const deleteRow = (payload) => {
    return { type: DELETE_ROW, payload };
};

export const updateGenericInputs = (payload) => {
    return { type: UPDATE_GENERIC_INPUTS, payload };
};

const initialState = {
    count: 3,
    genericInputs: {
        cf:0.995,
        corr:0.50
    },
    values: [
        {
            Name: "Peninsula",
            BL: "Oil Trading",
            EAD: 17857142,
            PD: 0.06,
            TD: 90,
            DV: 0.0189,
            LGD: 0.01,
            Deposit: 0.2,
            UC: "Gasoil or Diesel",
            id: 0
        },
        {
            Name: "MCC",
            BL: "Metal Trading",
            EAD: 17857142,
            PD: 0.06,
            TD: 90,
            DV: 0.0143,
            LGD: 0.15,
            Deposit: 0.2,
            UC: "Copper concentrates",
            id: 1
        },
        {
            Name: "Javelin",
            BL: "Coal Trading",
            EAD: 17857142,
            PD: 0.06,
            TD: 90,
            DV: 0.0189,
            LGD: 0.01,
            Deposit: 0.2,
            UC: "Gasoil or Diesel",
            id: 2
        }
    ]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CELL_VALUE':
            const updatedValues = state.values.map(obj =>
                obj.id === action.payload.rowId ? { ...obj, [action.payload.column]: isNaN(Number(action.payload.value)) ? action.payload.value : Number(action.payload.value)  } : obj
            );
            return {...state, values: updatedValues}
        case 'UPDATE_DATA':
            return {...state, values: action.payload}
        case 'INSERT_ROW':
            const newValues = action.payload.concat(state.values);
            return {...state, values:newValues};
        case 'DELETE_ROW':
            const filteredvalues = state.values.filter(val => val.id != action.payload);
            return {...state, values:filteredvalues};
        case "UPDATE_GENERIC_INPUTS":
            return {...state, genericInputs:action.payload};
        default:
            return state;
    }
};