import React from "react";
import Chart from "react-apexcharts";

export default function SmallBar(props) {
  const formatter = props.format ==='%' ?  function (val) {
    return val + "%" }: function (val) {return Math.round(val).toLocaleString()};

  const chartData = {
        series: [{
          name: 'series',
          data: props.data.values
        }],
        options: {
          colors: props.barColor,
          // theme: {
          //   monochrome: {
          //     enabled: true,
          //     color: props.barColor,
          //     // shadeTo: 'light',
          //     // shadeIntensity: 1.0
          //   }
          // },
          chart: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            height: '100%',
            type: 'bar',
            foreColor: '#d9d9d9',
            toolbar:{
              show: false
            },
          },
          //To show/hide tooltip on hover
          tooltip:{
            enabled: false,
          },
          legend:{
            show:false
          },
          plotOptions: {
            bar: {
              distributed: true,
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: formatter,
            // -20 for showing labels above the bar
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#d9d9d9"]
            }
          },
          xaxis: {
            categories: props.data.labels,
            position: 'bottom',
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: false
            }
          },
          grid:{
            yaxis: {
              lines: {
                  show: false
              }
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false
            }
          
          },
          title: {
            text: props.title || '',
            // floating: true,
            offsetY: 0,
            align: 'center',
            style:{
              fontWeight: 'normal',
            }
          }
        }
      };

    return (
        <div id="chart">
            <Chart options={chartData.options} series={chartData.series} type="bar" width={'80%'} height={180} />
        </div>
    );
}