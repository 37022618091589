//reducers
const initialState = {
    userName : 'admin'
}

export default (state = initialState, action) => {
switch (action.type) {
    default:
        return state;
}
};