import React, {  useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ncdf from "../utils/normal_dist";
import NormSInv from "../utils/inverse_normal_dist";
import Dashboard from "./Dashboard";
import Grid from "@material-ui/core/Grid";
import InputSummary from "./InputSummary";
import Paper from "@material-ui/core/Paper";
import InputChart from "./InputChart";
import OutputChart from "./OutputChart";
import { makeStyles } from '@material-ui/core/styles';
import EditableTable from './EditableTable'
import EditableTableOutput from './EditableTableOutput'
import Alert from "./Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { gridColumnLookupSelector } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingLeft:"5%",
      paddingRight:"5%"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
      background: "#1e375e",
      height: 325,
      borderRadius: '5px'
    },
    btn:{
        color:'#999999'
    }
  }));

export default function ExcelReader(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state) => state?.inputs?.values);
    const genericInputs = useSelector((state) => state?.inputs?.genericInputs);
    const [inputData, setInputData] = useState(data)
    const [inputSummary, setInputSummary]=useState(computeInputSummary(inputData))

    const outputData = runComputation(inputData);
    const outputSummary = computeOutputSummary(outputData);

    useEffect(()=>{
        setInputData(data);
        setInputSummary(computeInputSummary(data));
    },[data])

    const [errorMsg, setErrorMsg] = useState(null);

    function runComputation(data) {
        const {cf, corr} = genericInputs;
        let outData = [];
        data.forEach(function (d) {
            let ul = computeUL(d.PD, d.EAD, d.TD, d.DV, cf, corr);
            let el = computeEL(d.PD, d.LGD, d.EAD);
            let deposit = computeDeposit(d.EAD, d.Deposit);
            outData.push({ Name: d.Name, UL: ul, EL: el, Deposit: deposit });
        });
        return outData;
    }

    function computeInputSummary(inputData) {
        let sumEAD = 0;
        let sumPD = 0;
        let noOfCparties = inputData.length;
        let maxPD = 0,
            maxEAD = 0;
        inputData.forEach(function (d) {
            sumEAD += Number(d.EAD);
            sumPD += d.PD;
            if (d.PD > maxPD) maxPD = d.PD;
            if (d.EAD > maxEAD) maxEAD = d.EAD;
        });
        let avgPD = sumPD / noOfCparties;
        let avgEAD = sumEAD / noOfCparties;

        const summary = {
            avgPD: (avgPD * 100).toFixed(2) + "%",
            avgEAD: avgEAD,
            sumEAD: sumEAD,
            noOfCParties: noOfCparties,
            maxPD: (maxPD * 100).toFixed(2) + "%",
            maxEAD: maxEAD,
        };
        return summary;
    }
    function computeOutputSummary(outputData) {
        let sumEL = outputData.map((d) => d.EL).reduce((prev, next) => prev + next);
        let sumUL = outputData.map((d) => d.UL).reduce((prev, next) => prev + next);
        let sumDeposit = outputData.map((d) => d.Deposit).reduce((prev, next) => prev + next);

        let summary = { sumEL: sumEL, sumUL: sumUL, sumDeposit: sumDeposit };
        return summary;
    }
    return (
        <div className={classes.root}>
                <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <EditableTable data={inputData}/>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    
                    <Paper className={classes.paper}>
                        <EditableTableOutput data={outputData} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <InputSummary summary={inputSummary} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                    <Dashboard
                        data={outputData}
                        inputSummary={inputSummary}
                        outputSummary={outputSummary}
                    />
                    </Paper>
                </Grid>
                    <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <InputChart
                        indata={inputData}
                    />
                </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                    <OutputChart
                        indata={inputData}
                        outData={outputData}
                    />
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                open={errorMsg !== null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                autoHideDuration={5000}
                onClose={() => {
                    setErrorMsg(null);
                }}
            >
                <Alert severity="error">{errorMsg}</Alert>
            </Snackbar>
        </div>
    );
    }
function computeUL(pd, ead, td, dv, cf, corr) {
    let ul =
        ead *
        Math.min(1, NormSInv(cf)*Math.sqrt(td)*dv) *
        ncdf((NormSInv(pd) - corr * NormSInv(1 - cf)) / Math.sqrt(1 - corr * corr), 0, 1);
    return Math.round(ul);
}
function computeEL(pd, lgd, ead) {
    return Math.round(pd * lgd * ead);
}
function computeDeposit(ead, depositPct) {
    return Math.round(ead * depositPct);
}

