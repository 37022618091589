import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SmallBar from "./SmallBar"

const useStyles = makeStyles((theme)=>({
    root: {
        flexGrow: 1,
        color: '#d9d9d9'
    },
    title: {
        fontSize: 20,
    },
    subTitle: {
        fontSize: 24,
        marginBottom: "10px",
        color: '#f7e53b',
        fontWeight: 'bold',
    },
}));
//In order: exposure, PD, 
const barColors = ['#5f6483','#798fb0'];

export default function InputSummary(props) {
    const classes = useStyles();
    const [summary, setSummary] = useState(props.summary);
    const exposure = [summary.maxEAD, summary.avgEAD];
    const pd = [summary.maxPD, summary.avgPD];
    
    useEffect(()=>{
        setSummary(props.summary);
    },[props.summary])
    
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <CardContent >
                        <Typography className={classes.title} gutterBottom>
                            FUND SIZE
                        </Typography>
                        <Typography className={classes.subTitle}>
                            {Math.round(props.summary.sumEAD).toLocaleString() || 0}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardContent >
                            <Typography className={classes.title} gutterBottom>
                                NO. OF C'PARTIES
                            </Typography>
                            <Typography className={classes.subTitle}>
                                {props.summary.noOfCParties || 0}
                            </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SmallBar data={{labels: ["HIGHEST", "AVERAGE"], values:exposure}} barColor={[barColors[0]]} title={"Exposure"} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SmallBar data={{labels: ["HIGHEST", "AVERAGE"], values:pd}} barColor={[barColors[1]]} title={"Probability of Default"} format={'%'}/>
                </Grid>
            </Grid>
        </div>
    );
}
