
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle, TextField, DialogActions, Button, DialogContent, DialogContentText } from "@material-ui/core";
import {updateGenericInputs} from "../ducks/inputs";

export default function SettingDialog(props) {
    const dispatch = useDispatch();
    const { onClose, open } = props;
    const storedGenericInputs = useSelector((state) => state?.inputs?.genericInputs);
    const [gInputs, setGInputs] = useState(storedGenericInputs);
  
    const handleClose = () => {
        onClose();
    };
    const handleSave = () => {
        onClose();
        dispatch(updateGenericInputs(gInputs));
    };

    const handleCFValue = (e) =>{
        // console.log('Value', e.target.value);
        setGInputs({...gInputs, cf:e.target.value});
    }
    const handleCorrValue = (e) =>{
        // console.log('Value', e.target.value);
        setGInputs({...gInputs, corr:e.target.value});
    }
  
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Generic Inputs</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please specify the values of confidence level and correlation. 
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="cf"
            label="Confidence Level"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={gInputs?.cf}
            onChange={handleCFValue}
          />
          <TextField
            autoFocus
            margin="normal"
            id="corr"
            label="Correlation"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={gInputs?.corr}
            onChange={handleCorrValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
  